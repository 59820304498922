export const SOCKET_SEND = 'socketSend';
export const SOCKET_RESPONSE = 'socketResponse';
export const SOCKET_READY = 'socketReady';
export const SOCKET_RECONECTING = 'socketReconecting';
export const SOCKET_CONECTING = 'socketConecting';
export const SOCKET_ERROR = 'socketError';
export const SOCKET_CLOSED = 'socketClosed';
export const EE_STOPLISTENCHATCOUNTER = 'stopListenChatCounter'
export const EE_STARTLISTENCHATCOUNTER = 'startListenChatCounter'
export const EE_DOCUMENTCLICK = 'documentClick'

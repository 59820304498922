import BaseComponent from './BaseComponent'
import {SOCKET_RESPONSE} from '../events/EventEmitterList'
import {RESPONSE_UPDATEVIEWERS} from '../events/SocketEventList'

export default class InputUsersAutocompleteComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._componentData = {
            visible: false,
            viewers: [],
            filter: '',
            addAutocompletedName: () => {}
        };
        this.setComponentData(data);
    }

    _setEventEmitter(){
        this._eventEmitter.on(SOCKET_RESPONSE, (response) => {
            switch(response.event){
                case RESPONSE_UPDATEVIEWERS:
                    let viewers = response.viewers
                    this._updateViewersList(viewers);
                    break;
            }
        });
    }

    _setEvents(){
        let userLines = this._domElement.querySelectorAll('[role="name"]');

        for (let i = 0; i < userLines.length; i++) {
            let userLine = userLines[i];

            userLine.addEventListener('click', (event) => {
                event.preventDefault();

                let name = userLine.dataset['name'];
                this._componentData.addAutocompletedName(name);
                this.close();
            }, false);
        }
    }

    _getHtml(){
        let visible = this._componentData.visible;

        let html = `
            <div class="videov-chat__inputBaloon">
                ${visible ?
                    `<div class="baloon_box">${this._getListHtml()}</div>` : ''
                }
            </div>`;

        return html;
    }

    _getListHtml = () => {
        let viewers = this._componentData.viewers;
        viewers = this._filterViewers(viewers);
        let html = '';

        if(viewers.length){
            viewers.forEach((viewer) => {
                html += `
                    <div class="videov-chat__inputBaloon__user" data-name="${viewer.displayname}" role="name">
                        ${viewer.displayname}
                    </div>`;
            });
        } else {
            html = `<div class="videov-chat__inputBaloon__user">Matches not found</div>`;
        }
        
        return html;
    }

    _updateViewersList = (viewers) => {
        this.setComponentData({viewers: viewers});
    }

    _filterViewers = (viewers = []) => {
        let filter = this._componentData.filter;

        if(filter){
            viewers = viewers.filter((viewer) => {
                let name = viewer.displayname;
                let regExp = new RegExp(`^${filter}`);
                let match = name.match(regExp);

                return match ? true : false;
            })
        }

        return viewers.slice(0, 5);
    }

    close = () => {
        this.setComponentData({
            visible: false,
            filter: ''
        });
    }

    open = (filter = '') => {
        this.setComponentData({
            visible: true,
            filter: filter
        });
    }
}
import BaseComponent from './BaseComponent'
import RoomSelectorButtonComponent from './RoomSelectorButtonComponent'
import {SOCKET_RESPONSE} from '../events/EventEmitterList'
import {RESPONSE_UPDATEVIEWERS, RESPONSE_ROOMSCOUNT} from '../events/SocketEventList'

export default class RoomSelectorComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._componentData = {
            rooms: {
                local: '',
                global: '',
            },
            currentRoom: ''
        };

        this.setComponentData(data);
    }

    _getHtml(){
        let roomsCount = this._componentData.roomsCount;
        let rooms = this._componentData.rooms;
        let currentRoom = this._componentData.currentRoom;
        let html =`
            <div class="videov-chat__room-selector__buttons">
                <div class="videov_btn_box_wrapper" role="global" ></div>
                <div class="videov_btn_box_wrapper" role="local" ></div>
                <div class="videov_btn_box_wrapper">
                    <div class="videov_btn_box">
                        <div class="videov-chat__room-selector__button ${currentRoom == '' ? 'active_btn' : ''}" role="mute"><span>Mute</span></div>
                        <div class="videov_btn_counter mute_chat" >
                            <span role="mute_counter">No chat</span>
                        </div>
                    </div>
                </div>
            </div>`;

        return html;
    }

    _setEvents(){
        let muteButton = this._domElement.querySelector('[role="mute"]');

        muteButton.addEventListener('click', (event) => {
            event.preventDefault();

            this._muteChat();
        }, false)
    }

    _setChildrenComponents(){
        let globalBlock = this._domElement.querySelector('[role="global"]');
        let localBlock = this._domElement.querySelector('[role="local"]');

        let globalData = {
            room: this._componentData.rooms.global,
            title: 'Community',
            openViewersBlock: this._componentData.openViewersBlock,
            closeViewersBlock: this._componentData.closeViewersBlock,
            currentRoom: this._componentData.currentRoom,
            changeRoom: this._changeRoom,
            socketSend: this._componentData.socketSend,
        };

        let localData = {
            room: this._componentData.rooms.local,
            title: 'This Video',
            openViewersBlock: this._componentData.openViewersBlock,
            closeViewersBlock: this._componentData.closeViewersBlock,
            currentRoom: this._componentData.currentRoom,
            changeRoom: this._changeRoom,
            socketSend: this._componentData.socketSend,
        };

        this._childrenComponents.global = this._deployChildComponent(RoomSelectorButtonComponent, globalBlock, globalData, this._childrenComponents.global);
        this._childrenComponents.local = this._deployChildComponent(RoomSelectorButtonComponent, localBlock, localData, this._childrenComponents.local);
    }

    _changeRoom = (room) => {

        let data = {
            event: 'changeRoom',
            room: room,
        }

        this._componentData.socketSend(data);

        this.setComponentData({currentRoom: room});
        
        document.querySelector('[role="muted"]').style.display ='none';
        document.querySelector('[role="unmuted"]').style.display ='block';
    }

    _muteChat(){

        let data = {
            event: 'muteChat',
        }

        this._componentData.socketSend(data);
        this.setComponentData({currentRoom: ''});

        let globalRoom = this._childrenComponents.global;
        globalRoom.startMute();

        let localRoom = this._childrenComponents.local;
        localRoom.startMute();

        document.querySelector('[role="unmuted"]').style.display ='none';
        document.querySelector('[role="muted"]').style.display ='flex';

        let event = new CustomEvent("chatMuted");
        document.dispatchEvent(event);
    }

}
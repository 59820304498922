import MainComponent from './components/MainComponent'
import {
    SOCKET_RESPONSE, 
    SOCKET_READY, 
    SOCKET_RECONECTING, 
    SOCKET_CONECTING, 
    SOCKET_ERROR, 
    SOCKET_CLOSED, 
    EE_STOPLISTENCHATCOUNTER, 
    EE_STARTLISTENCHATCOUNTER, 
    EE_DOCUMENTCLICK
} from './events/EventEmitterList'

// @see \Chat_View_Helper_VideoVChat::getJsToInitializeChat
window.VideoVChat = class VideoVChat {

    constructor(chatBlock, setting = {} ) {

        console.trace('constructor VideoVChat ', this);
        window.VideoVChatInstance = this;
    
        if(!chatBlock || chatBlock.length ){
            return false;
        }

        let defaults = {
            socketConnection: '',
            baseUrl: ''
        }

        this._socket = null;
        this._setting = {...defaults, ...setting};

        //Chat data
        this._chatData = {
            currentUser: {
                session: chatBlock.dataset['session'],
                owner: setting.owner
            },
            rooms: {
                global: 'global_' + parseInt(chatBlock.dataset['global_room_id']),
                local: 'local_' + parseInt(chatBlock.dataset['local_room_id']),
            },
            newWindwoUrl: `${this._setting.baseUrl}/chat/${parseInt(chatBlock.dataset['local_room_id'])}?chat=1`
        }
        
        this._mainComponent = new MainComponent(chatBlock, this._chatData);
        this._initSocket();

        document.addEventListener("startListenChatCounter", (event) => {
            this._mainComponent.triggerEvent(EE_STARTLISTENCHATCOUNTER);
        });

        document.addEventListener("stopListenChatCounter", (event) => {
            this._mainComponent.triggerEvent(EE_STOPLISTENCHATCOUNTER);
        });

        document.addEventListener("click", (event) => {
            this._mainComponent.triggerEvent(EE_DOCUMENTCLICK, event);
        });
    }
    
    _initSocket = () => {
        if(this._socket){
            this._socket.close();
        }
        this._mainComponent.triggerEvent(SOCKET_CONECTING);
        this._socket = new WebSocket(this._setting.socketConnection);
        this._socket.onopen = () => {
            if(this._socketReconectTimeout){
                clearTimeout(this._socketReconectTimeout);
            }
            this._mainComponent.triggerEvent(SOCKET_READY, {socket: this._socket});
        };
          
        this._socket.onclose = (event) => {
            this._mainComponent.triggerEvent(SOCKET_CLOSED);
            this._tryReinitSocket();
        };
          
        this._socket.onmessage = (responce) => {
            let data = responce.data;
            data = JSON.parse(data);
            this._mainComponent.triggerEvent(SOCKET_RESPONSE, data);
        };
          
        this._socket.onerror = (error) => {
            this._mainComponent.triggerEvent(SOCKET_ERROR);
            this._tryReinitSocket();
        };

    }

    _tryReinitSocket = () => {
        if(this._socketReconectTimeout){
            clearTimeout(this._socketReconectTimeout);
        }
        this._socketReconectTimeout = setTimeout(() => {
            this._mainComponent.triggerEvent(SOCKET_RECONECTING);
            this._initSocket();
        }, 10000);
    }
}
import BaseComponent from './BaseComponent'
import {EE_DOCUMENTCLICK} from '../events/EventEmitterList'
import { h } from 'preact';
import {render as pRender } from 'react-dom'
import EmojiPicker from 'emojione-picker';

export default class EmojiComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._componentData = {
            visible: false,
            emojiList: [],
            addEmoji: () => {}
        };

        this.setComponentData(data);
    }


    _setEventEmitter(){
        this._eventEmitter.on(EE_DOCUMENTCLICK, (event) => {
            let visible = this._componentData.visible;

            if (event.target.closest('[role="emojiholder"]') || event.target.closest('[role="toggleemoji"]')){
                return;
            }

            if(!visible){
                return;
            }

            this.setComponentData({visible: false});
        });
    }

    _setEvents(){
    }

    _getHtml(){
        let visible = this._componentData.visible;

        let html = `
            <div class="videov-chat__emoji">
                ${visible ? '<div role="emojiholder"></div>' : ''}
            </div>`;
            
        return html;
    }

    _renderEmoji(){
        let emojiholder = this._domElement.querySelector('[role="emojiholder"]');
        if(emojiholder){
            pRender(<EmojiPicker onChange={this._addEmoji} search={true} />, emojiholder);
        }
        
    }

    _setChildrenComponents(){
        this._renderEmoji();
    }
    
    _addEmoji = (emoji) => {
        this._componentData.addEmoji(emoji);
    }
}
import BaseComponent from './BaseComponent'
import {SOCKET_RESPONSE, EE_DOCUMENTCLICK} from '../events/EventEmitterList'
import {RESPONSE_UPDATEVIEWERS, RESPONSE_ROOMVIEWERS} from '../events/SocketEventList'

export default class RoomSelectorButtonComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._roomsUpdateInterval = null;

        this._componentData = {
            title: '',
            room: '',
            currentRoom: '',
            viewers: [],
            isViewersOpener: false,
        };

        this.setComponentData(data);
    }

    _setEventEmitter(){
        this._eventEmitter.on(SOCKET_RESPONSE, (response) => {
            switch(response.event){
                case RESPONSE_UPDATEVIEWERS:
                    let room = this._componentData.room;
                    let currentRoom = this._componentData.currentRoom;

                    if(room == currentRoom){
                        clearTimeout(this._roomsUpdateInterval);
                        this._onViewersUpdate(response.viewers);
                    }else{
                        this._setRoomUpdater();
                    }

                    break;
                case RESPONSE_ROOMVIEWERS:
                    if(response.room == this._componentData.room){
                        this._onViewersUpdate(response.viewers);
                    }
                    break;
            }
        });

        this._eventEmitter.on(EE_DOCUMENTCLICK, (event) => {
            let isViewersOpener = this._componentData.isViewersOpener;
            let toggleUsersButton = this._domElement.querySelector('[role="toggleusers"]');

            if (event.target.closest('[role="viewers"]') || event.target.closest('[role="toggleusers"]') == toggleUsersButton){
                return;
            }

            let targetButton = event.target.closest('[role="toggleusers"]');
            if(toggleUsersButton && targetButton && toggleUsersButton.dataset['room'] == targetButton.dataset['room']){
                return;
            }

            if(!isViewersOpener){
                return;
            }

            this.setComponentData({isViewersOpener: false});
        });
    }

    _getHtml(){
        let viewers = this._componentData.viewers;
        let room = this._componentData.room;
        let currentRoom = this._componentData.currentRoom;
        let title = this._componentData.title;
        let isViewersOpener = this._componentData.isViewersOpener;

        let html =`
                <div class="videov_btn_box">
                    <div class="videov-chat__room-selector__button ${room == currentRoom ? 'active_btn' : ''}" role="switchroom">${title}</div>
                    <div class="videov_btn_counter">
                        ${currentRoom ? 
                            `<div class="counter_role_box ${isViewersOpener ? 'active_switcher' : ''}" role="toggleusers" data-room="${room}">
                                <span role="global_counter">${viewers.length}</span>
                                <span>online</span>
                            </div>` : ''}
                    </div>
                </div>`;

        return html;
    }

    _setEvents(){
        let toggleUsersButton = this._domElement.querySelector('[role="toggleusers"]');
        let switchRoomButton = this._domElement.querySelector('[role="switchroom"]');

        toggleUsersButton && toggleUsersButton.addEventListener('click', (event) => {
            event.preventDefault();

            let isViewersOpener = this._componentData.isViewersOpener;
            if(isViewersOpener){
                let viewers = this._componentData.viewers;
                this._componentData.closeViewersBlock(viewers);
            } else {
                let viewers = this._componentData.viewers;
                this._componentData.openViewersBlock(viewers);
            }

            this.setComponentData({isViewersOpener: !isViewersOpener});
        });
        
        switchRoomButton.addEventListener('click', (event) => {
            event.preventDefault();

            let room =  this._componentData.room;
            this._componentData.changeRoom(room);

            let customEvent = new CustomEvent("chatUnmuted");
            document.dispatchEvent(customEvent);
        }, false);

    }

    _onViewersUpdate = (viewers = []) => {
        this.setComponentData({
            viewers: viewers
        });
    }

    _setRoomUpdater = () => {
        let room = this._componentData.room;
        let currentRoom = this._componentData.currentRoom;

        this._getRoomViewers(room);
        clearTimeout(this._roomsUpdateInterval);

        if(!currentRoom){
            return;
        }

        this._roomsUpdateInterval = setTimeout( () => {
            this._setRoomUpdater();
        }, 30000)
    }

    _getRoomViewers = (room) => {

        let data = {
            event: 'getRoomViewers',
            room: room,
        }

        this._componentData.socketSend(data);
    }

    startMute(){
        this._setRoomUpdater();
    }

}
import BaseComponent from './BaseComponent'
import {SOCKET_RESPONSE, EE_STOPLISTENCHATCOUNTER, EE_STARTLISTENCHATCOUNTER, EE_DOCUMENTCLICK} from '../events/EventEmitterList'
import {RESPONSE_MESSAGE, RESPONSE_CLEARUSERMESSAGES, RESPONSE_LASTMESSAGES, RESPONSE_UPDATEMESSAGE, RESPONSE_SUBSCRIBE} from '../events/SocketEventList'
import emojione from 'emojione'

export default class MessagesComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this.messageColor = '';

        this._componentData = {
            currentUser: {},
            messages: [],
            keepScrollPosition: () => {},
            onReply: () => {}
        };

        this._openedMessageId = 0;

        this._newMessageCounter = 0;
        this._counterIsStarted = false;

        this.setComponentData(data);
    }

    _setEventEmitter(){
        document.addEventListener(
            'chat.changeNickColor',
            event => {
                console.log('ON chat.changeNickColor MessagesComponent ', event, event.detail);

                const user = event.detail.user;
                if (!!user.color) {
                    this.messageColor = user.color;
                }
            }
        );

        console.debug('_setEventEmitter ', this._eventEmitter);

        this._eventEmitter.on(SOCKET_RESPONSE, (response) => {
            console.debug('this._eventEmitter.on(SOCKET_RESPONSE, (response) => {   response ', response);

            switch(response.event){
                case RESPONSE_SUBSCRIBE:
                    this.setComponentData({
                        messages: []
                    });
                    break;
                case RESPONSE_MESSAGE:
                    let message = response.message;
                    this._updateChatMessages(message);

                    if(this._counterIsStarted){
                        this._newMessageCounter += 1;
                        this._dispatchCounter(this._newMessageCounter);
                    }
                    
                    break;
                case RESPONSE_CLEARUSERMESSAGES:
                    let user_id = response.user_id
                    this._clearMessagesByUser(user_id);
                    break;
                case RESPONSE_LASTMESSAGES:
                    let messages = response.messages
                    this.setComponentData({
                        messages: messages
                    });
                    break;
                case RESPONSE_UPDATEMESSAGE:
                    let updatedMessage = response.message
                    this._updateSingleChatMessage(updatedMessage);
                    break;
            }

        });

        this._eventEmitter.on(EE_STARTLISTENCHATCOUNTER, (response) => {
            this._counterIsStarted = true;
        });

        this._eventEmitter.on(EE_STOPLISTENCHATCOUNTER, (response) => {
            this._counterIsStarted = false;
            this._newMessageCounter = 0;
            this._dispatchCounter(this._newMessageCounter);
        });

        this._eventEmitter.on(EE_DOCUMENTCLICK, (event) => {

            if (event.target.closest('[role="tooltipswitcher"]')){
                return;
            }

            this._closeAllTooltips();
        });
    }

    _dispatchCounter = (counter = 0) =>{
        let event = new CustomEvent("newChatMessageCount", {detail:
            {newChatMessageCount: this._newMessageCounter}
        });
        document.dispatchEvent(event);
    }

    _renderComplete(){
        this._componentData.keepScrollPosition();
    }

    _getHtml(){
        let messages = this._componentData.messages;

        let html = `
            <div class="videov-chat__content__messages" role="messages">
                ${messages.map((message) => {
                    switch(message.message.type){
                         case 'message':
                             return this._getMessage(message);
                         case 'notification':
                             return this._getNotification(message);
                    }
                 }).join('')}
            </div>`;

        return html;
    }

    _getMessage = (message) => {
        let date = new Date(message.timestamp*1000);
        let hours = date.getHours();
        let minutes = "0" + date.getMinutes();
        let seconds = "0" + date.getSeconds();
        let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

        let messageText = message.message.text;
        let messageId = message.message.id;
        let username = this._componentData.currentUser.displayname;
        let regExp = new RegExp(`@${username}`);
        let match = messageText.match(regExp);
        let isMarked =  match ? true : false;
        let photo = message.owner.photo;
        let openedMessageId = this._openedMessageId;

        messageText = messageText.replace(/\n/gm, '<br />');
        messageText = emojione.toImage(messageText);
        return `
            <div class="videov-chat__message ${isMarked ? 'marked' : ''}" role="message" data-id="${messageId}" data-owner="${message.owner.displayname}">
                <div class="videov-chat__message__author_img">
                    <img src="${photo}">
                </div>
                <div class="videov-chat__message__textbox">
                    <span class="videov-chat__message__author" style="color:${this.messageColor};">${message.owner.displayname}: </span>
                    <span class="videov-chat__message__text" style="color:${this.messageColor};">${messageText}</span>
                </div>
                <div class="videov-chat__message_editbtn" role="tooltipswitcher">
                    <img src="/assets/img/videovchat/edit_btn.svg">
                </div>
                <div class="videov-chat__message_edit ${messageId == openedMessageId ? 'active_edit' : ''}" role="tooltip">
                    <div class="videov-chat__message_reply" role="reply">
                        <img src="/assets/img/videovchat/reply.svg">
                        <span>Reply</span>
                    </div>
                    ${this._getDeleteButton(message)}
                </div>
            </div>`
    }

    _getNotification = (message) => {
        return `
            <div class="videov-chat__message" role="notice">
                <span class="videov-chat__message__text">${message.message.text}</span>
            </div>`
    }
    
    _getDeleteButton = (message) => {

        let html = '';
        if(message.message.deleted){
            return html;
        }

        let user = this._componentData.currentUser;
        let isAllowed = false;

        if(user.role == 'moderator' || user.role == 'administrator'){
            isAllowed = true;
        } else if(user.user_id == message.owner.user_id){
            isAllowed = true;
        }

        if (isAllowed){
            html = `
                <div class="videov-chat__message_delete" role="delete">
                    <img src="/assets/img/videovchat/delete.svg">
                    <span>Delete</span>
                </div>`;
        } 

        return html;
    }
    
    _closeAllTooltips(){
        let tooltips = this._domElement.querySelectorAll('[role="tooltip"]');
        for (let i = 0; i < tooltips.length; i++) {
            let tooltip = tooltips[i];
            this._setopenedMessageId(0);
            tooltip.classList.remove("active_edit");
        }
    }

    _setEvents(){

        let tooltipswitcherBtns = this._domElement.querySelectorAll('[role="tooltipswitcher"]');
        let replyBtns = this._domElement.querySelectorAll('[role="reply"]');
        let deleteBtns = this._domElement.querySelectorAll('[role="delete"]');
        let tooltips = this._domElement.querySelectorAll('[role="tooltip"]');

        for (let i = 0; i < tooltipswitcherBtns.length; i++) {
            let tooltipswitcherBtn = tooltipswitcherBtns[i];

            tooltipswitcherBtn.addEventListener('click', (event) => {
                event.preventDefault();

                let messageBlock = tooltipswitcherBtn.closest('[role="message"]');
                let messageId = messageBlock.dataset['id'];
                this._setopenedMessageId(messageId);

                for (let i = 0; i < tooltips.length; i++) {
                    let tooltip = tooltips[i];
                    tooltip.classList.remove("active_edit");
                }

                let tooltip = messageBlock.querySelector('[role="tooltip"]');
                tooltip.classList.add("active_edit");
            });
        }

        for (let i = 0; i < replyBtns.length; i++) {
            let replyBtn = replyBtns[i];

            replyBtn.addEventListener('click', (event) => {
                event.preventDefault();

                let messageBlock = replyBtn.closest('[role="message"]');
                let ownnerName = messageBlock.dataset['owner'];

                this._componentData.onReply(ownnerName);
            });
        }

        for (let i = 0; i < deleteBtns.length; i++) {
            let deleteBtn = deleteBtns[i];

            deleteBtn.addEventListener('click', (event) => {
                event.preventDefault();

                let messageBlock = deleteBtn.closest('[role="message"]');
                let messageId = messageBlock.dataset['id'];

                this._deleteMessage(messageId);
            });
        }
        
    }

    _updateChatMessages(message){
        let chatMessages = this._componentData.messages;

        chatMessages.push(message);
        if(chatMessages.length > 100){
            chatMessages.shift();
        }

        this.setComponentData({messages: chatMessages});
    }

    _updateSingleChatMessage(message){
        let chatMessages = this._componentData.messages;

        chatMessages = chatMessages.map( (item) => {
            if(item.message.type == 'message' && item.message.id && item.message.id == message.message.id){
                return message;
            }
            return item;
        });

        this.setComponentData({messages: chatMessages});
    }

    _clearMessagesByUser(user_id){
        let chatMessages = this._componentData.messages;

        chatMessages = chatMessages.map((messageLine) => {
            if(messageLine.owner.user_id == user_id){
                if(messageLine.message.type == 'message'){
                    messageLine.message.text = '[message is deleted]';
                }
            }
            return messageLine;
        });

        this.setComponentData({messages: chatMessages});
    }

    warning = (text) => {
        let notice = {
            message: {
                type: 'notification',
                text: text
            }
        };

        let chatMessages = [];
        chatMessages.push(notice);

        this.setComponentData({messages: chatMessages});
    }

    _setopenedMessageId = (id = 0) => {
        this._openedMessageId = id;
    }

    _deleteMessage = (id) => {
        let data = {
            event: 'deleteMessage',
            id: id,
        }

        this._componentData.socketSend(data);
    }
}
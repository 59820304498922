import BaseComponent from './BaseComponent'
import InputUsersAutocompleteComponent from './InputUsersAutocompleteComponent'
import EmojiComponent from './EmojiComponent'
import emojione from 'emojione'
  
const TEXT_LIMIT = 200;

export default class InputComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._componentData = {
            toggleSettingsBlock: () => {},
            toggleViewersBlock: () => {}

        };
        this.setComponentData(data);
    }

    _setEvents(){
        let sendButton = this._domElement.querySelector('[role="send"]');
        let textarea = this._domElement.querySelector('[role="textarea"]');
        let toggleSettingsButton = this._domElement.querySelector('[role="togglesettings"]');
        let toggleEmojiButton = this._domElement.querySelector('[role="toggleemoji"]');
        
        sendButton.addEventListener('click', (event) => {
            event.preventDefault();

            let textarea = this._domElement.querySelector('[role="textarea"]');
            let count_box = this._domElement.querySelector('[role="chars_count_now"]');
            let messageText = this._getTextFromElement(textarea);

            count_box.innerHTML = 0;
            textarea.innerHTML = '';

            this._sendChatMassage(messageText);
        })

        textarea.addEventListener('keydown', this._inputHandler);
        textarea.addEventListener('input', this._inputHandler);

        toggleSettingsButton.addEventListener('click', (event) => {
            event.preventDefault();

            this._componentData.toggleSettingsBlock();
        })


        toggleEmojiButton.addEventListener('click', (event) => {
            event.preventDefault();

            this._toggleEmojiBlock();
        })
    }

    _getHtml(){
        let user = this._componentData.currentUser;
        let html = `
                <div class="videov-chat__inputWrapper">
                <div role="baloon"></div>
                <div class="videov-chat__textareaWrapper">
                    <div class="videov-chat_settings_box">
                        <button class="videov-chat__input__button videov-chat__input__button_settings" role="togglesettings">
                            <img src="/assets/img/videovchat/settings.svg">
                        </button>
                    </div>
                    <div class="videov-chat_send_wrapper">
                        <div class="videov-chat_send_wrapper_user">
                            <img src="${user.photo ? user.photo : '/assets/img/videovchat/nophoto_user_thumb_profile.png'}">
                        </div>
                        <div class="videov-chat__textarea_border_1">
                            <div class="videov-chat__textarea_border_2">
                                <button class="videov-chat__input__button videov-chat__input__button_emoji" role="toggleemoji">
                                    <img src="/assets/img/videovchat/emoji.svg">
                                </button>
                                <div contenteditable="true" class="videov-chat__textarea" role="textarea"></div>
                                <div class="chars_counter_box">
                                    <span class="chars_now" role="chars_count_now" >0</span>/<span class="chars_max">${TEXT_LIMIT}</span>
                                </div>
                            </div>
                        </div>
                        <div class="videov-chat_send_wrapper_sendbtn">
                            <button class="videov-chat__input__button videov-chat__input__button_send" role="send">Submit</button>
                        </div>
                    </div>
                </div>
                <div class="videov-chat__input__controls" >
                    <div class="videov-chat__input__buttons">
                    </div>
                </div>
                <div  id="emoji" role="emoji"></div>
            </div>`;

        return html;
    }
    
    _setChildrenComponents(){
        let baloonBlock = this._domElement.querySelector('[role="baloon"]');
        let emojiBlock = this._domElement.querySelector('[role="emoji"]');

        let autocompleteData = {
            addAutocompletedName: this.addAutocompletedName
        }
        this._childrenComponents.autocomplete = this._deployChildComponent(InputUsersAutocompleteComponent, baloonBlock, autocompleteData, this._childrenComponents.autocomplete);

        let emojiData = {
            addEmoji: this._addEmoji
        }
        this._childrenComponents.emoji = this._deployChildComponent(EmojiComponent, emojiBlock, emojiData, this._childrenComponents.emoji);
    }

    _sendChatMassage = (messageText) => {
        messageText = messageText.trim();
        if(!messageText){
            return false;
        }

        let data = {
            event: 'message',
            message: messageText
        }
        
        this._componentData.socketSend(data);
    }

    _checkAutocomplete = (messageText = '') => {
        let text = messageText;
        let autocompleteComponent =  this._childrenComponents.autocomplete;
        let autocompleteData = autocompleteComponent.getComponentData();
        let isVisible = autocompleteData.visible;
        let match = text.match(/(^@|\s@)(\S*)$/)

        if(match){
            autocompleteComponent.open(match[2]);
        } else{
            if(isVisible){
                autocompleteComponent.close();
            }
        }
    }

    addAutocompletedName = (name) => {
        let textarea = this._domElement.querySelector('[role="textarea"]');
        let messageText = this._getTextFromElement(textarea);
        name = '@' + name  + ' ';

        if( messageText.match(/(\s@)(\S*)$/) ){
            name = ' ' + name;
        }

        let newText = messageText;
        newText = newText.replace(/(^@|\s@)(\S*)$/, name);
        messageText = newText.length > TEXT_LIMIT ? messageText : newText;
        this._setEditableTextAsHtml(textarea, messageText);

        let event = new Event('keydown');
        textarea.dispatchEvent(event);
    }

    addReplydName = (name) => {
        let textarea = this._domElement.querySelector('[role="textarea"]');
        let messageText = this._getTextFromElement(textarea);
        name = '@' + name  + ' ';

        if( messageText.match(/(\s@)(\S*)$/) ){
            name = ' ' + name;
        }

        let newText = messageText + name;
        messageText = newText.length > TEXT_LIMIT ? messageText : newText;
        this._setEditableTextAsHtml(textarea, messageText);

        let event = new Event('keydown');
        textarea.dispatchEvent(event);
    }

    _toggleEmojiBlock(){
        let emojiComponent =this._childrenComponents.emoji;
        let emojiComponentData = emojiComponent.getComponentData();
        emojiComponent.setComponentData({visible: !emojiComponentData.visible});
    }

    _addEmoji = (emoji) => {

        let emojiText = '';
        emojiText += emoji.shortname;

        let textarea = this._domElement.querySelector('[role="textarea"]');
        let messageText = this._getTextFromElement(textarea);

        let newText = messageText + emojiText;
        messageText = newText.length > TEXT_LIMIT ? messageText : newText;
        this._setEditableTextAsHtml(textarea, messageText);

        let event = new Event('keydown');
        textarea.dispatchEvent(event);
    }

    _inputHandler = (event) => {
        
        let textarea = this._domElement.querySelector('[role="textarea"]');
        let count_box = this._domElement.querySelector('[role="chars_count_now"]');
        let messageText = this._getTextFromElement(textarea);

        if(event.keyCode == 13 && event.shiftKey){
            messageText = messageText.replace(/(\n){2,}/gm, '\n');
            this._setEditableTextAsHtml(textarea, messageText);
            return;
        } else if (event.keyCode == 13){
            event.preventDefault();
            count_box.innerHTML = 0;
            textarea.innerHTML = '';
            this._sendChatMassage(messageText);
            return;
        }

        let count = messageText.length;
        if(count > TEXT_LIMIT){
            messageText = messageText.substring(0, TEXT_LIMIT);
            this._setEditableTextAsHtml(textarea, messageText);
            count = TEXT_LIMIT;
        }

        count_box.innerHTML=count;

        this._checkAutocomplete(messageText);
    }

    _getTextFromElement = (element) => {
        let text = '';
        let childrenNodes = element.childNodes;
        for (let i = 0; i < childrenNodes.length; i++) {
            let node = childrenNodes[i];

            switch(node.nodeName){
                case '#text':
                    text += node.textContent;
                    break;
                case 'BR':
                    text += '\n'
                    break;
                case 'IMG':
                    text += node.title ? node.title : '';
                    break;
            }
        }

        if(text.match(/\n\n$/gm)){
            return text;
        }

        text = text.replace(/\n$/gm, '');

        return text;
    }

    _setEditableTextAsHtml = (element, text = '') => {
        let html = text.replace(/\n/gm, '<br>')
  
        //find emojione shortcodes
        let match = html.match(/(:[^:]+:)/g);
        if(match){
            match.forEach((shortcode)=>{
                let img  = emojione.shortnameToImage(shortcode);
                if(img == shortcode){
                    return;
                }

                img = this._createElementFromHTML(img);
                img.setAttribute('title', shortcode);

                let regExp = new RegExp(`${shortcode}`);
                html = html.replace(regExp, img.outerHTML);
            })
        }

        element.innerHTML = html;
        this._placeCaretAtEnd(element);
    }

    _placeCaretAtEnd = (el) => {
        el.focus();
        if (typeof window.getSelection != "undefined"
                && typeof document.createRange != "undefined") {
            let range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            let sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
            let textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
        }
    }
}
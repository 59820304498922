import BaseComponent from './BaseComponent'
import {SOCKET_RESPONSE, EE_DOCUMENTCLICK} from '../events/EventEmitterList'
import {RESPONSE_UPDATEUSER} from '../events/SocketEventList'
import Picker from 'vanilla-picker'

const COMMON_COLORS = [
    {code: '#9B9B9B', title: 'Grey'},
    {code: '#F5A623', title: 'Orange'},
    {code: '#BD10DE', title: 'Purple'},
    {code: '#F8E71D', title: 'Yellow'},
    {code: '#4791E2', title: 'Blue'},
    {code: '#4FE3C1', title: 'Aqua'},
]

export default class SettingsComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._colorpicker = null;

        this._componentData = {
            visible: false,
            currentUser: {},
            commonColors: COMMON_COLORS
        };
        this.setComponentData(data);
    }

    _setEventEmitter(){
        document.addEventListener(
            'chat.changeNickColor',
            event => {
                console.log('ON chat.changeNickColor SettingsComponent ', event, event.detail);

                const user = event.detail.user;
                this._updateCurrentUser(user);
            }
        );

        this._eventEmitter.on(SOCKET_RESPONSE, (response) => {
            switch(response.event){
                case RESPONSE_UPDATEUSER:
                    console.log('SettingsComponent onResponseUpdateUser');

                    let user = response.user;
                    this._updateCurrentUser(user);
                    break;
            }
        });

        this._eventEmitter.on(EE_DOCUMENTCLICK, (event) => {
            let visible = this._componentData.visible;

            if (event.target.closest('[role="settings"]') || event.target.closest('[role="togglesettings"]')){
                return;
            }

            if(!visible){
                return;
            }

            this.setComponentData({visible: false});
        });
    }

    _setEvents(){
        let colorButtons = this._domElement.querySelectorAll('[role="selectcolor"]');
        let newWindowButton = this._domElement.querySelector('[role="new_window"]');
        
        for (let i = 0; i < colorButtons.length; i++) {
            let colorButton = colorButtons[i];

            colorButton.addEventListener('click', (event) => {
                event.preventDefault();

                let color = colorButton.dataset['color'];
                this._changeNickColor(color);
            }, false);
        }

        newWindowButton && newWindowButton.addEventListener('click', (event) => {
            event.preventDefault();

            this._componentData.onOpenNewWindow();
        }, false);

    }

    _getHtml(){
        let visible = this._componentData.visible;
        let html = `
            <div class="videov-chat__settings__content">
                ${visible ? 
                    `<div class="videov-chat__settings__group">
                        <div class="videov-chat__settings__group__title">Name color:</div>
                        <div class="videov-chat__settings__group__content">
                            <div class="videov-chat__settings__colorsector">
                                ${this._getColorsButtons()}
                                <button id="colorpicker" role="colorpicker"></button>
                            </div>
                        </div>
                    </div>
                    ` : ''}
            </div>`;

        return html;
    }

    _getColorsButtons = () => {
        let user = this._componentData.currentUser;
        let html = '';

        this._componentData.commonColors.forEach((color) => {
            html += `
                <div 
                    class="videov-chat__settings__colorsector__color ${user.color == color.code ? 'selected' : ''}" 
                    role="selectcolor" 
                    data-color="${color.code}" 
                    title="${color.title}" 
                    style="background-color: ${color.code}"
                ></div>`;
        })

        return html;
    }

    _setChildrenComponents(){
        const user = this._componentData.currentUser;
        let lastColorPickerColor = user.color ?  user.color : 'black';

        let colorPickerBlock = this._domElement.querySelector('[role="colorpicker"]');
        this._colorpicker = new Picker({
            parent: colorPickerBlock,
            popup: 'top',
            color: lastColorPickerColor,
            editor: false,
            alpha: false,
            onChange: (color) => {
                let balance = 0.2126*color.rgba[0] + 0.7152*color.rgba[1] + 0.0722*color.rgba[2];
                if(balance > 200){
                    this._colorpicker.setColor(lastColorPickerColor, true);
                    return
                }
                lastColorPickerColor = color.hex;
            },
            onDone: (color) => {
                this._changeNickColor(color.hex);
            }
        });
    }

    _changeNickColor = (color) => {
        let data = {
            event: 'changeNickColor',
            color: color,
        }

        // this._componentData.socketSend(data);
        console.log('changeNickColor ', data, this);

        document.dispatchEvent(
            new CustomEvent(
                'chat.changeNickColor',
                {
                    detail: {
                        event: 'changeNickColor',
                        user: {
                            color: color
                        }
                    }
                }
            )
        );

        this.setComponentData({
            visible: false
        });
    }

    _updateCurrentUser = (user) => {
        let data = {
            currentUser: {...this._componentData.currentUser, ...user}
        };
        this.setComponentData(data);
    }

}
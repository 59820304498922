import BaseComponent from './BaseComponent'
import ViewersComponent from './ViewersComponent'
import MessagesComponent from './MessagesComponent'
import RoomSelectorComponent from './RoomSelectorComponent'
import SettingsComponent from './SettingsComponent'
import InputComponent from './InputComponent'
import {SOCKET_RESPONSE, SOCKET_READY, SOCKET_RECONECTING, SOCKET_CONECTING, SOCKET_ERROR, SOCKET_CLOSED} from '../events/EventEmitterList'
import {RESPONSE_ANAUTHORIZED, RESPONSE_SUBSCRIBE} from '../events/SocketEventList'

export default class MainComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._socket = null;
        this._socketIsready = false;
        this._contentIsScroolled = false;

        this._componentData = {
            currentUser: {},
            viewers: [],
            rooms: {}
        };

        this.setComponentData(data);
    }

    _setEventEmitter(){
        this._eventEmitter.on(SOCKET_RESPONSE, (response) => {
            switch(response.event){
                case RESPONSE_SUBSCRIBE:
                    let user = {...response.user, client_id: response.client_id};
                    this._updateCurrentUser(user);
                    break;
                case RESPONSE_ANAUTHORIZED:
                    this._domElement.innerHTML = '<div class="videov-chat">Unauthorized access</div>';
                    break;
            }
        });

        this._eventEmitter.on(SOCKET_READY, (response) => {
            this._socket = response.socket;
            this._socketIsready = true;
            this._sendWarning('');
            this._subscribeUser();
        });

        this._eventEmitter.on(SOCKET_RECONECTING, (response) => {
            this._socketIsready = false;
            this._sendWarning('Connecting...');
        });

        this._eventEmitter.on(SOCKET_CONECTING, (response) => {
            this._socketIsready = false;
            this._sendWarning('Connecting...');
        });

        this._eventEmitter.on(SOCKET_ERROR, (response) => {
            this._socketIsready = false;
            this._sendWarning('Connecting...');
        });

        this._eventEmitter.on(SOCKET_CLOSED, (response) => {
            this._socketIsready = false;
            this._sendWarning('Connecting...');
        });
    }

    _getHtml(){
        let html = `
            <div class="videov-chat" >
                <div class="videov-chat_header">
                    <div class="videov-chat_title">
                        Live chat
                    </div>
                    <div class="videov-chat_question">
                        Choose chat mode
                    </div>
                </div>
                <div class="videov-chat__room-selector" role="roomselector"></div>
                <div class="videov-chat_mes_box">
                <div class="videov-chat_mes_box" role="unmuted">
                    <div class="videov-chat__viewers" role="viewers" id="videov-chat__viewers"></div>
                    <div id="videov-chat__content">
                        <div class="videov-chat__content__messages_before"></div>
                    <div class="videov-chat__content" role="content"></div>
                    </div>
                    <div class="videov-chat__input_box">
                        <div class="videov-chat__viewers" role="viewers"></div>
                        <div class="videov-chat__settings" role="settings"></div>
                        <div class="videov-chat__input" role="input"></div>
                    </div>
                </div>
                <div class="videov-chat_mes_box_muted" role="muted">
                    <div class="muted_chat_text_1">Chat in Mute</div>
                    <div class="muted_chat_text_2">You can choose chat’s options up</div>
                </div>
            </div>`;
        
        return html;
    }
    
    _setEvents(){
        let contentBlock = this._domElement.querySelector('[role="content"]');
        contentBlock.addEventListener('scroll', (event) => {
            if(contentBlock.scrollTop + contentBlock.clientHeight >= contentBlock.scrollHeight){
                this._contentIsScroolled = false;
            } else {
                this._contentIsScroolled = true;
            }

        })
    }

    _setChildrenComponents(){
        let contentBlock = this._domElement.querySelector('[role="content"]');
        let viewersBlock = this._domElement.querySelector('[role="viewers"]');
        let roomSelectorBlock = this._domElement.querySelector('[role="roomselector"]');
        let settingsBlock = this._domElement.querySelector('[role="settings"]');
        let inputBlock = this._domElement.querySelector('[role="input"]');

        let roomSelectorData = {
            rooms: {
                global: this._componentData.rooms.global,
                local: this._componentData.rooms.local,
            },
            currentRoom: this._componentData.rooms.local,
            toggleViewersBlock: this._toggleViewersBlock,
            openViewersBlock: this._openViewersBlock,
            closeViewersBlock: this._closeViewersBlock,
            socketSend: this._socketSend
        }
        this._childrenComponents.roomSelector = this._deployChildComponent(RoomSelectorComponent, roomSelectorBlock, roomSelectorData, this._childrenComponents.roomSelector);

        let messagesData = {
            currentUser: this._componentData.currentUser,
            keepScrollPosition: this._keepScrollPosition,
            onReply: this._onReply,
            socketSend: this._socketSend
        };
        this._childrenComponents.chatMessages = this._deployChildComponent(MessagesComponent, contentBlock, messagesData, this._childrenComponents.chatMessages);

        let viewersData = {
            currentUser: this._componentData.currentUser,
            socketSend: this._socketSend
        };
        this._childrenComponents.viewersList = this._deployChildComponent(ViewersComponent, viewersBlock, viewersData, this._childrenComponents.viewersList);

        let settingsData = {
            currentUser: this._componentData.currentUser,
            socketSend: this._socketSend,
            onOpenNewWindow: this._openNewWindow
        }
        this._childrenComponents.settings = this._deployChildComponent(SettingsComponent, settingsBlock, settingsData, this._childrenComponents.settings);

        let inputData = {
            currentUser: this._componentData.currentUser,
            toggleSettingsBlock: this._toggleSettingsBlock,
            socketSend: this._socketSend
        }
        this._childrenComponents.input = this._deployChildComponent(InputComponent, inputBlock, inputData, this._childrenComponents.input);
    }

    _toggleViewersBlock = () => {
        let viewersList = this._childrenComponents.viewersList;
        let viewersListData = viewersList.getComponentData();
        viewersList.setComponentData({visible: !viewersListData.visible});
    }

    _openViewersBlock = (viewers = []) => {
        let viewersList = this._childrenComponents.viewersList;
        let viewersListData = viewersList.getComponentData();
        viewersList.setComponentData({visible: open});
        viewersList.updateViewersLists(viewers);
    }

    _closeViewersBlock = () => {
        let viewersList = this._childrenComponents.viewersList;
        let viewersListData = viewersList.getComponentData();
        viewersList.setComponentData({visible: false});
    }

    _toggleSettingsBlock = () => {
        let settingsComponent =this._childrenComponents.settings;
        let settingsComponentData = settingsComponent.getComponentData();
        settingsComponent.setComponentData({visible: !settingsComponentData.visible});
    }

    _subscribeUser = () => {
        let data = {
            event: 'subscribe',
            room: this._componentData.rooms.local,
            rooms: this._componentData.rooms,
            session: this._componentData.currentUser.session,
            owner: this._componentData.currentUser.owner
        }

        this._socketSend(data);
    }

    _updateCurrentUser = (user) => {
        let viewersData = {
            currentUser: {...this._componentData.currentUser, ...user},
        };
        this.setComponentData(viewersData);
    }

    _openNewWindow = () => {
        let newWindwoUrl = this._componentData.newWindwoUrl;
        if(!newWindwoUrl){
            return;
        }

        let newWin = window.open(newWindwoUrl, 'window', 'width=600,height=400');
    }

    _keepScrollPosition = () => {
        let contentBlock = this._domElement.querySelector('[role="content"]');
        if(!this._contentIsScroolled){
            contentBlock.scrollTop = contentBlock.scrollHeight;
        }
    }

    _sendWarning = (text) => {
        let messagesComponent = this._childrenComponents.chatMessages;
        messagesComponent.warning(text);
    }

    _onReply = (name) => {
        let inputComponent = this._childrenComponents.input;
        inputComponent.addReplydName(name);
    }
    
    //Set event emitter events
    _socketSend = (data) => {
        console.debug('_socketSend  data ', data);

        if(!this._socketIsready){
            this._sendWarning('Connection not ready yet...');
        }

        data = JSON.stringify(data);
        this._socket.send(data);
    };
    
}
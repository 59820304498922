import BaseComponent from './BaseComponent'
import {moderatorIcon, okIcon, banIcon} from '../templates/icons'
import {SOCKET_SEND} from '../events/EventEmitterList'

export default class ViewersGroupComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._componentData = {
            viewers: [], 
            currentUser: {}
        };
        
        this.setComponentData(data);
    }

    _getHtml(){
        let viewers = this._componentData.viewers;
        let html = `
            <div class="videov-chat__viewers">
                ${viewers.map((viewer) => {
                    let html = `
                    <div class="videov-chat__viewer">
                        <div class="videov-chat__viewer_box">
                            <div class="videov-chat__viewer__photo">
                                <img src="${viewer.photo}">
                            </div>
                            <div class="videov-chat__viewer__name" style="color:${viewer.color};">
                                ${viewer.displayname}
                            </div>
                        </div>
                    </div>`;
        
                    return html;
                }).join('')}
            </div>`;

        return html;
    }

    _setEvents(){
        let roleButtons = this._domElement.querySelectorAll('[rel="viewers_changerole"]');
        for (let i = 0; i < roleButtons.length; i++) {
            let roleButton = roleButtons[i];

            roleButton.addEventListener('click', (event) => {
                event.preventDefault();

                let user_id = roleButton.dataset['user_id'];
                this._changeModeratorRole(user_id);
            }, false);
        }

        let banButtons = this._domElement.querySelectorAll('[rel="viewers_banuser"]');
        for (let i = 0; i < banButtons.length; i++) {
            let banButton = banButtons[i];

            banButton.addEventListener('click', (event) => {
                event.preventDefault();

                let user_id = banButton.dataset['user_id'];
                this._banUser(user_id);
            }, false);
        }
    }

    _getAdminControls = (viewer) => {
        let user = this._componentData.currentUser;

        if(user.user_id != viewer.user_id && user.role == 'administrator'){
            let title = '';
            let icon = '';

            if( viewer.role == 'moderator' ){
                title = 'Make user';
                icon = okIcon;
            } else {
                title = 'Make moderator';
                icon = moderatorIcon;
            }

            return `<a class="videov-chat__viewer__control-btn videov-chat__viewer__change-role" title="${title}" rel="viewers_changerole" data-user_id="${viewer.user_id}">
                        ${icon}
                    </a>`;
        }

        return '';
    }

    _getModerControls = (viewer) => {
        let user = this._componentData.currentUser;
        let isAllowed = true;

        if(user.user_id == viewer.user_id){
            isAllowed = false;
        } else if(user.role == 'user'){
            isAllowed = false;
        } else if(user.role == 'moderator' && (viewer.role == 'moderator' || viewer.role == 'administrator')){
            isAllowed = false;
        }
        
        if(isAllowed){
            let title = '';
            let icon = '';

            if( viewer.isBaned ){
                title = 'Unban';
                icon = okIcon;
            } else {
                title = 'Ban';
                icon = banIcon;
            }

            return `<a class="videov-chat__viewer__control-btn videov-chat__viewer__ban" title="${title}" rel="viewers_banuser" data-user_id="${viewer.user_id}">
                        ${icon}
                    </a>`;
        }

        return '';
    }

    _changeModeratorRole = (user_id) => {
        let data = {
            event: 'changeRole',
            target_id: user_id
        }

        this._componentData.socketSend(data);
    }

    _banUser = (user_id) => {
        let data = {
            event: 'banUser',
            target_id: user_id
        }

        this._componentData.socketSend(data);
    }
}
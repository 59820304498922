import BaseComponent from './BaseComponent'
import ViewersGroupComponent from './ViewersGroupComponent'
import {SOCKET_RESPONSE, EE_DOCUMENTCLICK} from '../events/EventEmitterList'
import {RESPONSE_UPDATEVIEWERS, RESPONSE_UPDATEUSER} from '../events/SocketEventList'

export default class ViewersComponent extends BaseComponent{

    constructor(parentBlock, data) {
        super(parentBlock);

        this._componentData = {
            moderators: [],
            users: [],
            currentUser: {},
            visible: false
        };

        this.setComponentData(data);
    }

    _setEventEmitter(){
        document.addEventListener(
            'chat.changeNickColor',
            event => {
                console.log('ON chat.changeNickColor ViewersComponent ', event, event.detail);

                const user = event.detail.user;
                this._updateCurrentUser(user);
            }
        );

        this._eventEmitter.on(SOCKET_RESPONSE, (response) => {
            switch(response.event){
                case RESPONSE_UPDATEUSER:
                    console.log('ViewersComponent onResponseUpdateUser');

                    let user = response.user;
                    this._updateCurrentUser(user);
                    break;
            }
        });

        this._eventEmitter.on(EE_DOCUMENTCLICK, (event) => {
            let visible = this._componentData.visible;

            if (event.target.closest('[role="viewers"]') || event.target.closest('[role="toggleusers"]')){
                return;
            }

            if(!visible){
                return;
            }

            this.setComponentData({visible: false});
        });
    }

    _getHtml(){
        const visible = this._componentData.visible;
        const users = this._componentData.users;
        const moderators = this._componentData.moderators;

        let html = `
            <div class="videov-chat__viewers__list">
                ${visible ? 
                    `<div class="videov-chat__viewers_box">
                        <div class="videov-chat__viewers_title">All users in this room</div>
                        <div class="videov-chat__viewers_counter">
                            <span role="local_counter">${users.length + moderators.length}</span>
                            <span>online</span>
                        </div>
                        <div class="videov-chat__viewers_box_list">
                            <div class="videov-chat__viewers__list">
                                <div class="videov-chat__viewers__list__content" role="moderators"></div>
                            </div>
                            <div class="videov-chat__viewers__list">
                                <div class="videov-chat__viewers__list__content" role="users"></div>
                            </div>
                        </div>
                    </div>` : ''
                }
            </div>`;

        return html;
    }

    _setChildrenComponents(){
        let moderatorsBlock = this._domElement.querySelector('[role="moderators"]');
        let usersBlock = this._domElement.querySelector('[role="users"]');

        let moderatorsData = {
            viewers: this._componentData.moderators,
            currentUser: this._componentData.currentUser,
            socketSend: this._componentData.socketSend
        };

        let usersData = {
            viewers: this._componentData.users,
            currentUser: this._componentData.currentUser,
            socketSend: this._componentData.socketSend
        };

        this._childrenComponents.moderatorsGroup = this._deployChildComponent(ViewersGroupComponent, moderatorsBlock, moderatorsData, this._childrenComponents.moderatorsGroup);
        this._childrenComponents.roomSelector = this._deployChildComponent(ViewersGroupComponent, usersBlock, usersData, this._childrenComponents.roomSelector);
    }

    updateViewersLists = (viewers) => {
        let moderators = [];
        let users = [];

        viewers.forEach((viewer) => {
            if(viewer.role == 'administrator' || viewer.role == 'moderator'){
                moderators.push(viewer);
            } else {
                users.push(viewer);
            }
        })

        let viewersData = {
            moderators: moderators,
            users: users
        };

        this.setComponentData(viewersData);
    }

    _updateCurrentUser = (user) => {
        let viewersData = {
            currentUser: {...this._componentData.currentUser, ...user},
        };
        this.setComponentData(viewersData);
    }
}
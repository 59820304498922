const EventEmitter = require('events');

export default class BaseComponent {

    constructor(parentBlock){
        this._parentBlock = parentBlock;
        this._eventEmitter = new EventEmitter();
        this._componentData = {};
        this._childrenComponents = {};
        this._domElement = null;
        
        this._setEventEmitter();
    }

    setComponentData(data = {}){
        this._componentData = {...this._componentData, ...data};
        this.render();
    }

    getComponentData(){
        return this._componentData;
    }

    _createElementFromHTML(htmlString) {
        let div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        
        if(div.childNodes.length !== 1){
            let className = this.constructor.name;
            throw new Error(`Component ${className} do not have root node`)
        }

        return div.firstChild;
    }
    
    render() {
        if(!this._parentBlock){
            return;
        }
        
        let html = this._getHtml();
        let newDomElement = this._createElementFromHTML(html)

        if(!this._domElement || this._domElement.parentElement !== this._parentBlock){
            this._domElement = newDomElement;
            this._parentBlock.appendChild(this._domElement);
        } else {
            this._domElement.innerHTML = '';
            this._domElement.innerHTML = newDomElement.innerHTML;
        }

        this._setEvents();
        this._renderComplete();
        this._setChildrenComponents();
    }

    _deployChildComponent(className, parentBlock, data, ref){

        if(ref && ref instanceof className){
            ref._parentBlock = parentBlock;
            ref.setComponentData(data);
            return ref;
        }
            
        return  new className(parentBlock, data, this._chatEventEmitter);
    }

    triggerEvent(eventName, data = {}){
        this._eventEmitter.emit(eventName, data);

        for (let key in this._childrenComponents){
            let component = this._childrenComponents[key];
            if(component instanceof BaseComponent){
                component.triggerEvent(eventName, data)
            } else if(component instanceof Array){
                component.forEach((c) => {
                    c.triggerEvent(eventName, data);
                })
            }
        }
    }

    _getHtml(){}
    _setEvents(){}
    _setChildrenComponents(){}
    _setEventEmitter(){}
    _renderComplete(){}
}